@import './_config';




.textError {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	color: $color_red;
	font-family: $font-family_1;
	font-size: 263px;
	font-style: normal;
	font-weight: 700;
}
.textRetourAccueil {
	display: flex;
	justify-content: center;
	color: $color_red;
	font-family: $font-family_1;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	padding: 69px 0px;
}
h2.error-Title {
	display: flex;
	flex-wrap: wrap;
	width: 53%;
    height: 3%;
	justify-content: center;
	color: $color_red;
	font-family: $font-family_1;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
}
a.textRetourAccueil {
	display: flex;
	flex-wrap: wrap;
}
@media (max-width: 920px) {
	h2.error-Title {
		top: 64%;
		right: 19%;
		font-size: 32px;
		
	}
}
@media (max-width: 768px) {
	.textError {
		
		font-size: 96px;
		padding: 69px 0px;
	}
	h2.error-Title {
		
		top: 37%;
		right: 15%;
		font-size: 23px;
	
	}
	a.textRetourAccueil {
		font-size: 14px;
		margin-top: 30px;
	}
}

@media (max-width: 425px) {
	h2.error-Title {
		top: 25%;
		right: 24%;
	}
}