@import '_config';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Passion+One&family=Press+Start+2P&display=swap');

* {
	box-sizing: border-box;
	margin: 0px;
}
body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	max-width: 1440px;
	min-width: 320px;
	gap: 30px;
	background-color: rgba(255, 255, 255, 0.041);
	
}

main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 1240px;
	margin: 251px 100px;
	gap: 50px;
	
}
img, a {
	display: flex;
	flex-wrap: wrap;
}
p {
    display: flex;
    flex-wrap: wrap;
	color: $color_1;
	line-height: 150%;
	font-size: 19px;
	font-family: 'montserrat';
	font-weight: lighter;
	text-align: justify;
	
}
span {
	display: flex;
    flex-wrap: wrap;
	font-size: 23px;
	color: #be1a1a;
}
@media (max-width: 1024px) {
	main {
		display: flex;
		flex-direction: column;
		width: 850px;
		margin: 250px 100px;
		gap: 100px;
	}
	p {
		display: flex;
		flex-wrap: wrap;
		color: $color_1;
		font-size: 16px;
		font-family: 'montserrat';
		font-weight: lighter;
		text-align: justify;
		
	}
}
@media (max-width: 768px) {
	body {
		max-width: 767px;
		min-width: 320px;
		
	}
	main {
		margin: 150px 50px;
		gap: 50px;
		width: 667px;
	}
	
	p {
		display: flex;
		flex-wrap: wrap;
		color: $color_1;
		@include text-layout('Montserrat', 400, 0em, left, bolder, 150%, 12px);
		text-align: justify;
	   
	}
}
@media (max-width: 425px) {
	main {
		margin: 110px auto;
		gap: 70px;
		width: 290px;
	}
	body {
		max-width: 425px;
		min-width: 330px;
		margin: 0px 0px;
	}
	p {
		display: flex;
		flex-wrap: wrap;
		color: $color_1;
		line-height: 15px;
		text-align: justify;
	   	
		
		
	}
	
}
@media (max-width: 330px) {
	body {
		display: none;
	}
}