@import './_config';


.scrolling-banner {
    width: 100%;
    height: 100%;
    position: relative;
    top: 15px;
    right: 0px;
    overflow: hidden;
}
.scrolling-content {
   width: 100%;
    height: 100%;
    gap: 12px;
    white-space: nowrap;
    animation: scroll 15s linear infinite;
}
@keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
}
.scrolling-slide {
    display: inline-block; 
}
.scrolling-slide img {
    width: auto; 
    height: auto; 
    
}
.scrolling-slide  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.logos-Description {
    font-size: 16px;
    font-family: 'monteserrat';
    font-weight: normal;
    color:  #254758;
    margin: 0px;
}

@media (max-width: 1024px) {
    .scrolling-banner {
        position: relative;
        top: 5px;
        right: 0px;
        width: 100%;
        height: 100%;
    }
}
@media (max-width: 768px) {
    .scrolling-banner {
        width: 100%;
        height: 9%;
        top: 25px;
        right: 4px;
    }
    .scrolling-content {
        width: 100%;
        height: 100%;
        gap: 7px;
    }
    .logos-Description {
        font-size: 12px;
        margin: 0px 3px;
    }
}
@media (max-width: 425px) {
    .scrolling-banner {
        width: 100%;
        height: 19%;
        top: 1px;
        right: 0px;
    }
    .scrolling-content {
        width: 100%;
        height: 41%;
        gap: 7px;
    }
    .logos-Description {
        font-size: 9px;
    }
}