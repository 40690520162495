
.image-with-modals {
    position: relative;
    width: 100%;
    height: auto;
}
.background-image {
    width: 100%;
    height: 778px;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
}
.icon-buttons {
    position: relative;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    
}
.plus-1 {
    position: absolute;
    top: 24%;
    left: 18%;
    margin: 0 10px; 
        transition: transform 0.3s ease; 

        &:hover {
            transform: scale(1.2); 
        }
}
.plus-2 {
    position: absolute;
    top: 68%;
    left: 36%;
    margin: 0 10px; 
        transition: transform 0.3s ease; 

        &:hover {
            transform: scale(1.2); 
        }
    
}
.plus-3 {
    position: absolute;
    top: 10%;
    left: 81%;
    margin: 0 10px; 
    transition: transform 0.3s ease; 

    &:hover {
        transform: scale(1.2); 
    }
   
}  
.plus-4 {
    position: absolute;
    top: 33%;
    left: 61%;
    margin: 0 10px; 
    transition: transform 0.3s ease; 

    &:hover {
        transform: scale(1.2); 
    }
}
.plus-5 {
    position: absolute;
    top: 59%;
    left: 90%;
    margin: 0 10px; 
        transition: transform 0.3s ease; 

        &:hover {
            transform: scale(1.2); 
        }

    
}
.plus-6 {
    position: absolute;
    top: 79%;
    left: 19%;
    margin: 0 10px; 
        transition: transform 0.3s ease; 

        &:hover {
            transform: scale(1.2); 
        }
} 
.icon-buttons img {
    width: 50px; /* Ajustez la taille des icônes selon vos besoins */
    height: auto;
    margin: 5px;
    cursor: pointer;
    background-color: white;
    border-radius: 100px;
    transition: transform 0.3s ease;
}
  
.modal {
    position: fixed;
    top: 0;
    left: -2px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
}
.frimousse {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    object-fit: cover;
    width: 150px;
    height: 150px;
    border-radius: 150px;
}  
.modal-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    background: #fde8eb;
    padding: 35px;
    width: 790px;
    margin-top: 200px;
    border-radius: 15px;
    text-indent: 8%;
    text-align: justify;
}

h4 {
    font-size: 20px;
    width: 549px;
    margin-bottom: 15px;
    
}
  
.close-button {
    display: flex;
    justify-content: flex-end;
    width: 677px;
    height: 31px;
    color: #613d18;
    cursor: pointer;
   
}
  
.modal-content p {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 10px;
    font-size: 16px;
    width: 100%;
}
body.modal-open {
    overflow: hidden;
}

@media (max-width: 1024px) {

    .background-image {
        width: 100%;
        height: 668px;
        border-radius: 15px;
    }
    .image-with-modals {
       
        width: 100%;
        height: 100%;
    }
    
    h4 {
        font-size: 20px;
        width: 200px;
        margin-bottom: 10px;
    }
}
@media (max-width: 871px) {
    h4 {
        font-size: 20px;
        width: 434px;
        margin-bottom: 10px;
    }
}
@media (max-width: 768px) {
    .background-image {
       
        width: 100%;
        height: 668px;
        border-radius: 15px;
    }
    .image-with-modals {
        position: relative;
        width: 665px;
        height: 100%;
    }
    .modal {
        width: 100%;
    }
   
    .modal-content {
        width: 500px;
        height: 601px;
        margin-top: 40px;
        width: 600px;
    }
    .close-button {
        width: 447px;
    }
    p.modal-content {
        font-size: 14px;
    }
    h4 {
        font-size: 20px;
        width: 200px;
        margin-bottom: 10px;
    }
}
@media (max-width: 425px) {
    .background-image {
        width: 100%;
        height: 299px;
        border-radius: 15px;
    }
    .image-with-modals {
        position: relative;
        width: 289px;
        height: 23%;
    }
    .icon-buttons img {
        width: 29px;
        margin: -12px;
        border-radius: 100px;
    }
    .modal {
        width: 200%;
       
    }
    
    h4 {
        font-size: 16px;
        width: 142px;
        margin-bottom: 10px;
    }
    .modal-content {
        position: absolute;
        top: 272px;
        right: 509px;
        width: 250px;
        height: auto;
        margin-top: -88px;
        padding: 0px 18px 18px 7px;
       
    }
    .close-button {
        font-size: 15px;
    }
    .modal-content p {
        margin-bottom: 10px;
        font-size: 8px;
        text-align: justify;
      
    }
    .close-button {
        width: 333px;
        padding-top: 10px;
    }
}