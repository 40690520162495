@import './_config';

.wrapper-collapses {
	@include speudo-El (flex, row, wrap, space-between, space-between);
   	margin-bottom: 74px;
}
div.collapse {
	@include speudo-El (flex, row, wrap, center, flex-start);
   	background-color: $color_grisclaire;
	color: $color_red;
	height: 30px;
    width: 100%;
	position: relative;  
	border-radius: 15px; 
}
.about_main_collapse {
	@include speudo-El (flex, row, wrap, center, center);
	width: 120%;
	margin-bottom: 57px;
}
div.collapse_content_hidden {
	@include speudo-El (flex, row, wrap, center, center);
	visibility: hidden;
    opacity: 0;
    height: 0;
}
// collapse
.wrapper-collapses  {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
	height: auto;
	gap: 30px;
}	

.about_main {
	margin: 20px 72px;
    width: 88%;
}

.collapse_title {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
	height: 40px;
    width: 100%;
	border-radius: 0px 0px 15px 15px;
	justify-content: space-between;
    align-content: center;
	align-items: center;
    font-family: "montserrat";
    font-weight: 400;
	background-color: #305D73;
    color: #FFF;
	font-size: 15px;
	text-transform: uppercase;
	padding: 10px 20px 10px 15px;
	box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
}
.btn-deroulant-text {
	font-family: "montserrat";
    font-weight: 400;
    color: #305D73;
    font-size: 16px;
    padding: 0px 46px;
    margin-top: 36px;
}

.collapseHeight {
	margin-bottom: 40px;
	width: 100%;
	margin-top: 25px;
}
div.collapse_content {
	width: 100%;
	position: absolute;
	top: 30px;
	height: auto;
	margin-bottom: 170px;
	background-color: #cae2f0;
    transition: 600ms;
    z-index: -1;
    color: #305D73;
	border-radius: 0px 0px 10px 10px;
}
@media (max-width: 1024px) {
	.about_main {
		margin: 20px 42px;
		width: 88%;
	}
	.btn-deroulant-text {
		font-size: 15px;
		padding: 0px 30px;
	}
}
@media (max-width: 768px) {
		
	.about_main {
		margin-bottom: 20px;
	}
	.about_main_collapse {
		
		margin-bottom: 40px;
		
	}
	.collapse_title {
		height: 30px;
		border-radius: 0px 0px 15px 15px;
		font-size: 15px;
	}
	h3 {
		font-size: 18px;
	}
	div.collapse_content {
		height: 286px;
	}
	.btn-deroulant-text {
		font-size: 13px;
		padding: 0px 29px;
	}
    div.collapse {
        height: 30px;
    }	
}

@media (max-width: 425px) {
	.about_main {
		height: 33%;
		width: 100%;
		margin: 0px 0px;
	}
	.about_main_collapse {
		margin-bottom: 90px;
	}
	div.collapse_content {
		height: auto;
		top: 0px;
		padding: 7px 0px;
	}
	.btn-deroulant-text {
		font-size: 11px;
    	padding: 0px 14px;
    	margin-top: 37px;
	}
	.collapse_title {
		font-size: 10px;
		
	}
	.collapseHeight {
		margin: 0px;
		width: 100%;
	}
}