@import './_config';

@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Passion+One&family=Press+Start+2P&display=swap');



h2 {
	position: absolute;
    top: 45%;
    right: 19%;
    width: 31%;
    height: 190px;
    color: #305D73;
    font-family: "Passion One";
    font-weight: lighter;
    font-size: 30px;
    letter-spacing: 1px;
    line-height: 34px;
    transform: translateY(-50%);
}
	
h3 {
    font-family: "Passion One";
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-align: left;
    line-height: 150%;
    font-size: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    color:  #254758;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
}

div {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	
}
a {
	display: flex;
	flex-wrap: wrap;
}
img, section {
	display: flex;
	flex-wrap: wrap;
}

.arrow_up {
	transform: rotate(0deg);
	transition: all 0.2s ease-in-out;
}
.arrow_down {
	transform: rotate(180deg);
	transition: all 0.2s ease-in-out;
}

@media (max-width: 1024px) {
	h2 {
		font-size: 22px;
		
	}
}
@media (max-width: 768px) {
	
	h2 {
		font-size: 19px;
		
	}
	.containerProfil {
		height: 763px;
    	width: 187%;
	}
	#titleProfil {
		height: 41px;
		width: 625px;
	}
	.wrapperEducation {
		height: 393px;
		width: 621px;
	}
	.education {
		width: 46%;
		height: 30%;
		font-size: 14px;
	}
	.containerBoxImage {
		height: 26%;
    	width: 100%;
	}
	.illustrationCard {
		height: 180px;
		width: 836px;
	}
	
	
}
@media (max-width: 425px) {
	.containerCard2 {
		height: 473px;
		width: 403px;
	}
	.about_main {
		width: 100%;
		margin: 0px;
	}
	.containerProfil {
		flex-direction: column;
		height: 607px;
		width: 100%;
	}
	#titleProfil {
		height: 19px;
		width: 333px;
	}
	.wrapperEducation {
		height: 379px;
		width: 389px;
	}
	.education {
		width: 48%;
		height: 36%;
		font-size: 12px;
	}
	.logo_Inc {
		margin: 16px 73px;
	}
	.containerBoxImage {
		height: 185px;
		width: 99%;
	}
	h2 {
		top: 89%;
    	right: 22%;
		font-size: 11px;
		line-height: 16px;
	}
}		

