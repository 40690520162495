@import './_config';



.title-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 350px;
	height: 30px;
	color: $color_1;
	font-family: $font-family_1;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 142.6%;
	text-decoration: none;
}
.h4 {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	bottom: -270px;
	right: 312px;
	width: 300px;
	height: 30px;
}
.projets {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	height: 23%;
	width: 52%;
}
.gallery-wrapper {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.041);
  align-content: center;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  
}

.gallery-item {
	position: relative;
	width: 776px;
	margin: 32px 217px;
	border-radius: 15px;

}

.image-overlay-wrapper {
  position: relative;
  border-radius: 15px;
}

.project-image {
	width: 776px;
    height: 500px;
    display: flex;
	border-radius: 15px;

}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent initial */
  transition: opacity 0.3s ease; /* Transition douce */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.overlay.active {
  opacity: 1; 
}

.overlay-image {
	width: 50px;
	height: 50px;
	margin: 28px; 
	background-color: aliceblue;
	border-radius: 100px;
	transition: transform 0.3s ease; /* Transition pour un effet d'animation */
}

.image-overlay-wrapper:hover .overlay {
  opacity: 1; 
}

.overlay-image:hover {
  transform: scale(1.2); /* Effet d'agrandissement au survol */
}


@media (max-width: 1232px) {
	.project-image {
		width: 60%;
		height: 100%;
		margin: 0 185px;
	}
	.gallery-item {
		width: 75%;
	}
	.overlay {
		top: 0px;
		left: 23%;
		width: 61%;
		height: 100%;
	}
}
@media (max-width: 1024px) {
	.project-image {
		width: 100%;
		height: 100%;
		margin: 0 0px;
	}
	.overlay {
		top: 0px;
		left: 0%;
		width: 100%;
		height: 100%;
	}
	.overlay-image {
		width: 27px;
		height: 27px;
	}	
}
@media (max-width: 768px) {
	
	.gallery-item {
		
		width: 150%;
		margin: 32px 136px;
		border-radius: 15px;
	}
	.project-image {
		width: 100%;
		height: 100%;
		margin: 0 ;
	}
	.overlay {
		top: 0px;
		left: -6%;
		width: 102%;
		height: 100%;
	}
	.overlay-image {
		width: 30px;
		height: 30px;
		margin: 28px;
		
	}
}
@media (max-width: 425px) {
	
	.gallery-item {
		display: flex;
		flex-direction: column;
		width: 80%;
		margin: 35px;
	}
}