
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Passion+One&display=swap');


.navContainer {
	position: fixed;
	top: 0px;
	z-index: 2;
	flex-direction: row;
	width: 1440px;
	height: 189px;
	padding: 0 100px;
	justify-content: space-between;
	align-items: center;
	background-color: #305d73;
	box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
}
img.name-logo {
	position: absolute;
    top: 96px;
    right: 892px;
    width: 54%;
    height: 148px;
    transform: translateY(-50%);
}

.boiteLogo {
	display: flex;
	flex-direction: row;
	width: 40%;
	height: 90px;
}
.img {
	display: flex;
	align-content: baseline;
	height: 46px;
	width: 145px;
}
.navBar {
	position: fixed;
	background-color: #FFC0CB;
	right: 17%;
	top: 30%;
	width: 3em;
	height: 35em;
	margin-top: -2.5em;
	border-radius: 65px;
	padding-top: 80px;
	
}
// animation
.animation {
	position: relative;
	width: 210px;
    height: 25px;
}
.container-Animation {
	position: absolute;
    top: 0px;
    right: 189px;
    width: 874px;
}
.letter {
    display: inline-block;
    color: white;
    font-family: 'Passion One';
    font-size: 50px;
	font-weight: bold;
	height: 70px;
    line-height: 58px; /* Pour centrer verticalement le texte */
    text-align: center; /* Pour centrer le texte horizontalement */
    z-index: 4;
	text-transform: uppercase;
    letter-spacing: 1px;
	background: linear-gradient(120deg, rgba(252, 250, 252, 0.97), #ffc0cb, #bddded );
    background-repeat: repeat;
    background-size: 80%;
    animation: animate 5s infinite;
	background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
@keyframes animate {
	0% {
	  background-position: -500%;
	}
	100% {
	  background-position: 500%;
	}
}
.socialIcons2 {
    position: absolute;
    top: 78px;
    right: 83px;
    gap: 20px;
}
.socialIcons2 a {
    margin: 0 10px; 
    transition: transform 0.3s ease; 
    img {
        width: 48px; 
        height: 48px;
		opacity: 0.4;
        
        &:hover {
            transform: scale(1.2); 
			opacity: 1;
        }
    }
}

@media (max-width: 1024px) {
	.navBar {
		right: 4%;
		top: 30%;
	}

	.navContainer {
		width: 100%;
		height: 180px;
		padding-left: 62px;
	}
	.boiteLogo {
		width: 47%;
		height: 130px;
	}
	.animation {
		position: absolute;
		width: 690px;
		height: 54px;
	}
	.container-Animation {
		top: 22px;
		right: -319px;
		padding: 0px 0px;
	}
	
	.letter {
		font-size: 36px;
		height: 70px;
		line-height: 63px; 
	}
	img.name-logo {
		position: relative;
		top: 64px;
		right: 69px;
	}
	.socialIcons2 {
		position: absolute;
		top: 79px; 
		right: 20px;
		gap: 4px;
	}

	
}

@media (max-width: 768px) {
	.navBar {
		display: flex;
		flex-direction: row;
		right: 22%;
		top: 10%;
		width: 55%;
		height: 4%;
		margin-top: 0px;
		padding: 0px;
		border-radius: 65px;
		writing-mode: horizontal-tb;
		text-orientation: initial;
		
	}
	.boiteLogo {
		
		width: 40%;
		height: 116px;
	}
	.navContainer {
		width: 100%;
    	height: 100px;
    	padding: 0px 10px;
	}
	
	img.name-logo {
		top: 44px;
		right: 19px;
		width: 32%;
		
	}
	.container-Animation {
	    top: 4px;
		right: -71px;
		padding: 0px 0px;
		width: 100%;
		height: 100%;
	}
	.animation {
		width: 483px;
		height: 21px;
	}
	.letter {
		font-size: 27px;
		height: 50px;
		line-height: 48px; 
	}
	.socialIcons2 {
		position: absolute;
		top: 49px;
		right: 56px;
		width: 131px;
		height: 47px;
		gap: 4px;
	}
	.socialIcons2 a {
		img {
			width: 35px;
    		height: 35px;
			
				
		}
		
	}
	
}

@media (max-width: 425px) {
	.navContainer {
		margin: 0px;
		padding: 0px;
	    width: 100%;
		height: 60px;
	}
	.boiteLogo {
		width: 415px;
		height: 47px;
	}
	img.name-logo {
		top: 24px;
		right: 1px;
		width: 15%;
		height: 53px;
	}
	.navBar {
		display: flex;
		flex-direction: row;
		right: 19%;
		top: 6%;
		width: 61%;
		height: 4%;
	}
	.socialIcons2 {
		top: 12px;
		right: -483px;
		width: 589px;
		height: 30px;
	}
	.socialIcons2 a img {
		width: 25px;
		height: 25px;
		opacity: 0.6;
	}
	.container-Animation {
		top: 5px;
    	right: -59px;
	}
	.animation {
		width: 340px;
		height: 21px;
	}
	.letter {
		font-size: 15px;
		height: 35px;
		letter-spacing: 0px;
		font-family: "montserrat";
		
	}
	
}
