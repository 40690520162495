@import './_config';
.containerFooter {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: $background-color_black;
    padding: 20px 0px;
    gap: 29px;
}

.imageFooter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.text_Footer {
    display: flex;
    flex-wrap: wrap;
    height: 46px;
    width: 99%;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-family: $font-family_1;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 143%;
    background-color: $background-color_black;
    border-radius: 0px;
}

.socialIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; 
	gap: 30px;
}

.socialIcons a {
    margin: 0 10px; 
    transition: transform 0.3s ease; 
    img {
        width: 48px; 
        height: 48px;
		opacity: 0.4;
        
        &:hover {
            transform: scale(1.2); 
            opacity: 1;
        }
    }
}

@media (max-width: 1024px) {
    .socialIcons a img {
        width: 40px;
        height: 40px;
        gap: 20px;
    }
    .text_Footer {
        font-size: 12px;
    }
}
@media (max-width: 768px) {
    .containerFooter {
        width: 100%;
    }
    .text_Footer {
        height: 46px;
        width: 92%;
        font-size: 12px;
    }
    .socialIcons a img {
        width: 35px;
        height: 35px;
        gap: 0px;
    }
}
@media (max-width: 425px) {
    .containerFooter {
        width: 100%;
    }
    .text_Footer {
        height: 46px;
        width: 92%;
        font-size: 11px;
    }
    .socialIcons a img {
        gap: 0px;
    }
}
