@import './_config';

h4 {
	&::after {
		display: flex;
		flex-wrap: wrap;
		color: $color_1;
		font-family: $font-family_1;
		font-size: 36px;
		font-style: normal;
		font-weight: 500;
		justify-content: center;
		padding-top: 90px;
	}
}

.wrapper-welcome {
	display: flex;
    position: relative;
    width: 100%;
    height: auto;
   
}
.presentation_image {
	display: flex;
	object-fit: cover;
	border-radius: 15px;
	width: 1240px;
	height: 614px;
	
}
.containerCard {
	display: flex;
	flex-wrap: wrap;
	object-fit: cover;
	border-radius: 10px;
	box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.3);
	width: 100%;
    height: 32%;
	
}
@media (max-width: 1024px) {
	
	.presentation_image {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}
	
}

@media (max-width: 768px) {
	.presentation_image {
		width: 100%;
		height: 100%;
	}
}
@media (max-width: 425px) {
	.presentation_image {
		width: 100%;
		height: 100%;
	}
	.wrapper-welcome {
		display: flex;
		position: relative;
		width: 100%;
		height: 100%;
	}	
	.containerCard {
		width: 100%;
		height: 6%;
	}
	.containerCard {
		width: 100%;
    	height: 9%;
	}
}



